import { create } from 'apisauce'

import { API_BASE_URL } from '../config/constants'

const handleResponse = async (response: any) => {
  if (!response.ok) {
    throw response;
  }

  return response.data;
}

async function request(path: any, method: any, data = null) {
  const api: any = create({
    baseURL: API_BASE_URL
  });

  return api[method](path, data)
    .then(handleResponse)
    .catch((response: any) => {
      console.error(response) // TODO handle error
    });
}

export const getApi = (url: any, params?: any) => request(url, 'get', params);
