import React, { memo } from "react";
import { IFlag } from "../IFlag";
// @ts-ignore
import { LazyLoadImage } from "react-lazy-load-image-component";

import styles from "./FlagBlock.module.scss";

const FlagBlock = (props: { flag: IFlag }) => {
  return (
    <div className={styles.root} title={props.flag.title}>
      <a
        className={styles.innerLink}
        href={props.flag.downloadUrl || props.flag.imageUrl}
        target="_blank"
        rel="noreferrer"
      > 
        <div className={styles.block}>
          <div className={styles.imgBlock}>
            <LazyLoadImage
              alt={props.flag.title}
              src={props.flag.thumbnailUrl}
            />
            <div className={styles.line}></div>
          </div>
          <div className={styles.titleBlock}>
            <div className={styles.title}>{props.flag.title}</div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default memo(FlagBlock);
