import React, { memo } from 'react';
import { IFlagsCollection } from './IFlagsCollection';

import Section from './Section';

import styles from './FlagsGallery.module.scss';

const FlagsGallery = (props: { data: IFlagsCollection }) => {

    return <div className={styles.container}>
        {Object.entries(props.data).map(([key, value], i) => <Section key={`section_${i}`} name={key} flags={value} />)}
    </div>
}

export default memo(FlagsGallery)
