import React, { memo, useState } from 'react';
import Toolbar from "../Toolbar";
import SideDrawer from '../SideDrawer/SideDrawer';

const Header = () => {
    const [sideDrawerOpen, setSideDrawerOpen] = useState(false)
    const drawerToggleClickHandler = () => {
        setSideDrawerOpen(!sideDrawerOpen);
    }

    return <>
        <Toolbar drawerToggleClickHandler={drawerToggleClickHandler} />
        { sideDrawerOpen && <SideDrawer drawerToggleClickHandler={drawerToggleClickHandler} />}
    </>

}

export default memo(Header)
