import React, { memo } from 'react'
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';
import styles from './Toolbar.module.scss'

const Toolbar = (props: { drawerToggleClickHandler: () => void; })  => {
    return <header className={styles.toolbar}>
        <nav className={styles.navigation}>
            <div>
                <DrawerToggleButton drawerToggleClickHandler={props.drawerToggleClickHandler} />
            </div>

            <div className={styles.logo}><img src="/logo.svg" alt="logo"/> - музей сцягоў</div>

            <div className={styles.spacer}>

            </div>
            <div className={styles.navigationItems}>
                <ul>
                    <li><a className={styles.red} href="https://t.me/dzechat_bot">+ Дадаць</a></li>
                </ul>
            </div>
        </nav>
    </header>
};

export default memo(Toolbar);