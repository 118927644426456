import React, { FunctionComponent, memo } from 'react'

import { ReactComponent as Animation } from '../../static/icons/loader.svg'

import styles from './Loading.module.scss'

const Loading: FunctionComponent<{}> = () => {
    return <div className={styles.loading}>
        <Animation />
    </div>
}

export default memo(Loading)
