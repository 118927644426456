import React, { memo } from 'react';
import styles from './SideDrawer.module.scss';
import ClickAwayListener from 'react-click-away-listener';


const SideDrawer = (props: { drawerToggleClickHandler: () => void; })  => {

    return <ClickAwayListener onClickAway={props.drawerToggleClickHandler}>
        <nav className={styles.sideDrawer}>
            <div>
                <ul>
                <li><a href="https://dze.chat">Мапа чатаў</a></li>
                    <li><a href="https://tips.dze.chat">Гайды</a></li>
                    <li><a href="https://t.me/dzechat_bot">Падтрымка</a></li>
                    <li><a className={styles.red} id="contact" href="https://t.me/dzechat_bot">+ Дадаць</a></li>
                </ul>
            </div>
            <div className={styles.spacer} />
            <div className={styles.meta}><img src="./logo.svg" alt="logo" /> - музей сцягоў</div>
        </nav>
    </ClickAwayListener>

};

export default memo(SideDrawer);