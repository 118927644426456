import React, { memo } from 'react';
import { IFlag } from '../IFlag';
import FlagBlock from './FlagBlock'

import styles from './Section.module.scss';

const Section = (props: { name: string, flags: IFlag[] }) => {

    return <div className={styles.root}>
        <div className={styles.header}>
            <h1>
                {props.name}
            </h1>

            <div className={styles.flagsContainer}>
                {props.flags.map((f,i)=><FlagBlock  key={`flag_${i}`} flag={f}/>)}
            </div>
        </div>

    </div>
}

export default memo(Section)
