import React, { useEffect, useState } from 'react'
import Header from './components/Header'
import Loading from './components/Loading'
import FlagsGallery, { IFlagsCollection } from './components/FlagsGallery'

import { getApi } from './services/api'
import endpoints from './config/endpoints'

const App = () => {
  const [flags, setFlags] = useState<IFlagsCollection>({});
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    loadFlags()
  }, [])


  function loadFlags() {
    getApi(endpoints.flags)
      .then(result => {
        setFlags(result.collection)
        setIsLoaded(true)
      })
      .catch(err => {
        // TODO handle error
        console.error(err)
      })
  }

  return (
    <>
      <Header />
      {isLoaded ?
        <div className="page-content">
          <FlagsGallery data={flags!} />
        </div>
        :
        <Loading />
      }
    </>
  )
}

export default App
